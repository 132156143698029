* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027, repeat); /*  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027, repeat); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  color: #FFFFFF;
  font-family: 'Courier Prime', monospace;
  overflow-x: hidden;
}

.about {
  max-width: 1440px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto 0 auto;
  position: relative;
}

.aboutImg {
  height: 250px;
  width: 250px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 50px;
}

.aboutLeft {
  width: 45%;
  line-height: 65px;
}

.aboutRight {
  width:45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
}

.aboutLeft h1 {
  font-size: 36px;
  font-weight: 700;
}

.aboutLeft h2 {
  font-size: 28px;
  display: flex;
  font-weight: 400;
}

.aboutSocials {
  list-style: none;
  display: flex;
  font-size: 42px;
  width: 40%;
  margin-top: 25px;
  justify-content: space-between;
}

.aboutSocials a {
  color: white;
}

.seeProjects {
  position: absolute;
  bottom: 25px;
}

.seeProjectsLink {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.bounce {
  animation: bounce 2s infinite;
  margin-top: 25px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.projects {
  min-height: 100vh;
}

.header {
  position: fixed;
  background-color: #16222A;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 100px 0 100px;
  z-index: 100;
  top: -100px;
  transition: all 1s ease-in-out;
}

.menu-icon {
  display: none;
}

.show {
  top: 0px;
  transition: all 0.5s ease-in-out;
}

.header ul {
  list-style: none;
  display: flex;
  font-size: 28px;
  justify-content: space-between;
}

.header nav {
  width: 700px;
}

.header .logo {
  font-size: 42px;
  cursor: pointer;
}

.header li, .header li a {
  cursor: pointer;
  color: rgb(170, 170, 170);
  text-decoration: none;
}

.header li:hover, .header li a:hover {
  color: white;
}

.experience {
  min-height: 100vh;
}

.projects h2, .experience h2 {
  width: 75%;
  margin: 0 auto 0 auto;
  padding-top: 150px;
  font-size: 36px;
}

.projectImg {
  width: 100%;
  display: block;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: 50px auto 0 auto;
  justify-content: space-between;
}

.project h3 {
  font-size: 28px;
  margin-bottom: 15px;
}

.project {
  margin-bottom: 50px;
  width: 600px;
}

.project p {
  text-align: justify; 
  width: 75%;
  margin: auto;
  display: flex;
  padding: 15px 0 15px 0;
}

.technologies span {
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;
  margin-right: 10px;
}

.technologies {
  margin-top: 15px;
}

.project .links a {
  color: white;
}

.project .links {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  margin-top: 15px;
}

.imageContainer {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #16222acc;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: all 1s ease;
}

.imageContainer:hover .overlay {
  height: 150px;
}

.timelineText {
  color: #16222a;
}

.vertical-timeline-element-date {
  position: absolute;
  left: -100%;
}

.timelineDate {
  padding-top: 15px;
  color: #16222a9c;
}

.timelineText h3 {
  font-weight: bolder;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
}

.experience h2 {
  margin-bottom: 50px;
}

.timelineTextContainer {
  display: flex;
  justify-content:space-between;
}

.linkActive {
  color: white !important;
}

.footer {
  height: 25vh;
}

.footer hr {
  margin-top: 75px;
  border: 1px solid #c0c0c01a;
}

.footerContainer {
  display: flex;
  justify-content:space-between;
  align-items: center;
  height: 100%;
  padding: 0 100px;
  font-size: 24px;
}

.footerSocials {
  list-style: none;
  display: flex;
  justify-content:space-between;
  width: 200px;
}

.footerSocials a {
  color: white;
  font-size: 42px;
}

@media only screen and (max-width: 1630px) {
  .projectsContainer {
    width: 90%;
  }

  .projects h2, .experience h2 {
    width: 90%;
  }
}

@media only screen and (max-width: 1350px) {
  .projectsContainer {
    justify-content: center;
  }
}

@media only screen and (max-width: 990px) {
  .about {
    flex-direction: column;
    padding-bottom: 150px;
    margin-top: 100px;
  }

  .aboutLeft {
    text-align: center;
    width: 90%;
  }

  .aboutLeft h2 {
    justify-content: center;
  }

  .aboutSocials {
    margin: 0 auto 50px auto;
  }

  .aboutRight {
    width: 75%;
  }

  .header nav {
    width: 100%;
  }

  .header ul {
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .header ul.active {
    background-color: #16222A;
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .header ul li {
    text-align: center;
    padding: 2rem;
  }

  .menu-icon {
    font-size: 1.8rem;
    cursor: pointer;
    float: right;
    display: inline;
  }
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 596px) {
  .aboutLeft h1 {
    font-size: 28px;
  }

  .aboutLeft h2 {
    font-size: 22px;
  }

  .aboutSocials {
    width: 75%;
  }

  .footerContainer {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .footerSocials {
    margin-bottom: 15px;
  }

  .header {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 467px) {
  .aboutLeft h1 {
    font-size: 24px;
  }

  .aboutLeft h2 {
    font-size: 18px;
  }

  .aboutSocials {
    width: 75%;
  }

  .aboutRight {
    width: 90%;
  }

  .technologies {
    font-size: 12px;
  }
}